
export default {
    sparql_endpoint: 'https://graphdb.dumontierlab.com/repositories/shapes-registry',
    // GraphDB: 164802 files in 1963 repositories
    // sparql_endpoint: 'https://data.index.semanticscience.org/query',
    // Virtuoso: 67884 files in 1181 repositories and 29 SPARQL endpoints
    prefix_registry: {
        "rdf":         "http://www.w3.org/1999/02/22-rdf-syntax-ns#",
        "rdfs":        "http://www.w3.org/2000/01/rdf-schema#",
        "owl":         "http://www.w3.org/2002/07/owl#",
        "skos":        "http://www.w3.org/2004/02/skos/core#",
        "dc":          "http://purl.org/dc/elements/1.1/",
        "dct":         "http://purl.org/dc/terms/",
        "dctypes":     "http://purl.org/dc/dcmitype/",
        "csvw":        "http://www.w3.org/ns/csvw#",
        "bl":          "https://w3id.org/biolink/vocab/",
        "biolink":     "http://w3id.org/biolink/vocab/",
        "d2s":         "https://w3id.org/d2s/",
        "omop":        "http://api.ohdsi.org/WebAPI/vocabulary/concept/",
        "cohd":        "https://w3id.org/trek/cohd/",
        "bio2rdf":     "http://bio2rdf.org/",
        "dbo":         "http://dbpedia.org/ontology/",
        "dbp":         "http://dbpedia.org/property/",
        "wd":          "http://www.wikidata.org/entity/",
        "wdt":         "http://www.wikidata.org/prop/direct/",
        "foaf":        "http://xmlns.com/foaf/0.1/",
        "idot":        "http://identifiers.org/idot/",
        "dcat":        "http://www.w3.org/ns/dcat#",
        "void":        "http://rdfs.org/ns/void#",
        "void-ext":    "http://ldf.fi/void-ext#",
        "obo":         "http://purl.obolibrary.org/obo/",
        "ncit":        "http://ncicb.nci.nih.gov/xml/owl/EVS/Thesaurus.owl#",
        "xsd":         "http://www.w3.org/2001/XMLSchema#",
        "schema":      "https://schema.org/",
        "cito":        "http://purl.org/spar/cito/",
        "prov":        "http://www.w3.org/ns/prov#",
        "pav":         "http://purl.org/pav/",
        "pplan":       "http://purl.org/net/p-plan#",
        "np":          "http://www.nanopub.org/nschema#",
        "npx":         "http://purl.org/nanopub/x/",
        "oad":          "http://culturalis.org/oad#",
        "virtrdf":      "http://www.openlinksw.com/schemas/virtrdf#",
        "sd":           "http://www.w3.org/ns/sparql-service-description#",
        "gpml":         "http://vocabularies.wikipathways.org/gpml#",
        "wp":           "http://vocabularies.wikipathways.org/wp#",
        "gr":           "http://purl.org/goodrelations/v1#"
    }
}